import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52735052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searchMain bg-fff m-t-8" }
const _hoisted_2 = { class: "d-f m-l-16 m-b-10" }
const _hoisted_3 = { class: "f-l" }
const _hoisted_4 = { class: "f-l m-l-5" }
const _hoisted_5 = { class: "f-l m-l-40" }
const _hoisted_6 = { class: "f-l m-l-5" }
const _hoisted_7 = { class: "d-f" }
const _hoisted_8 = { class: "d-f flex-d-c flex-w-auto m-l-16" }
const _hoisted_9 = { class: "label-text-12" }
const _hoisted_10 = { class: "d-f flex-d-c flex-w-auto m-l-16" }
const _hoisted_11 = { class: "label-text-12" }
const _hoisted_12 = { class: "d-f flex-d-c flex-w-auto m-l-16" }
const _hoisted_13 = { class: "label-text-12" }
const _hoisted_14 = {
  class: "flex-w-auto",
  style: {"padding-top":"25px","flex":"1.5"}
}
const _hoisted_15 = { class: "f-r" }
const _hoisted_16 = { class: "table-main bg-fff m-t-8 flex-w-auto" }
const _hoisted_17 = { class: "table-main bg-fff m-t-8 flex-w-auto" }
const _hoisted_18 = { class: "table-main bg-fff m-t-8 flex-w-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_main_wrapper = _resolveComponent("main-wrapper")!

  return (_openBlock(), _createBlock(_component_main_wrapper, { className: "p-l-r-10" }, {
    header: _withCtx(() => [
      _createVNode(_component_Breadcrumb, {
        dataSource: [{ name: _ctx.$t('salesmeasure.detail') }]
      }, null, 8, ["dataSource"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('salesmeasure.batchname')) + ":", 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.programAttachment.releaseBatchName), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('salesmeasure.releasedate')) + ":", 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$route.query.releaseDate), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('salesmeasure.programcode')), 1),
            _createVNode(_component_a_input, {
              value: _ctx.programCode,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.programCode) = $event)),
              maxlength: 50
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('salesmeasure.programnamecn')), 1),
            _createVNode(_component_a_input, {
              value: _ctx.programNameCn,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.programNameCn) = $event)),
              maxlength: 50
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('salesmeasure.programnameen')), 1),
            _createVNode(_component_a_input, {
              value: _ctx.programNameEn,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.programNameEn) = $event)),
              maxlength: 50
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_a_button, {
                class: "m-l-16",
                onClick: _ctx.handlerResetClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('salesmeasure.reset')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                class: "m-l-16",
                type: "primary",
                onClick: _ctx.handlerSearchClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('salesmeasure.search')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                class: "m-l-16",
                type: "primary",
                onClick: _ctx.handlerDownload
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('salesmeasure.download')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('salesmeasure.selectprogram')), 1),
        _createVNode(_component_a_table, {
          class: "tab-container default-self-table-text m-t-16",
          dataSource: _ctx.programAttachment.program,
          columns: _ctx.columns,
          pagination: false,
          "row-selection": _ctx.rowSelection,
          scroll: { y: _ctx.tableHeight, x: 1000 },
          rowClassName: 
                    (record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                ,
          rowKey: (item, index) => index
        }, {
          period: _withCtx(({ text }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text, (val) => {
              return (_openBlock(), _createElementBlock("p", { key: val }, _toDisplayString(val.from) + " - " + _toDisplayString(val.to) + " / " + _toDisplayString(val.submissionFrom) + " - " + _toDisplayString(val.submissionTo), 1))
            }), 128))
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "row-selection", "scroll", "rowClassName", "rowKey"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('salesmeasure.selectattch')), 1),
        _createVNode(_component_a_table, {
          class: "tab-container default-self-table-text m-t-16",
          dataSource: _ctx.programAttachment.attachment,
          columns: _ctx.columnsAttachment,
          pagination: false,
          "row-selection": _ctx.rowSelectionAt,
          scroll: { y: _ctx.tableHeight, x: 1000 },
          rowClassName: 
                    (record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                ,
          rowKey: (item, index) => index
        }, null, 8, ["dataSource", "columns", "row-selection", "scroll", "rowClassName", "rowKey"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('salesmeasure.salesmeasurelist')), 1),
        _createVNode(_component_a_table, {
          class: "tab-container default-self-table-text m-t-16",
          dataSource: _ctx.programAttachment.sml,
          columns: _ctx.columnsSmlFile,
          pagination: false,
          "row-selection": _ctx.rowSelectionSmlFile,
          scroll: { y: _ctx.tableHeight, x: 1000 },
          rowClassName: 
                    (record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                ,
          rowKey: (item, index) => index
        }, null, 8, ["dataSource", "columns", "row-selection", "scroll", "rowClassName", "rowKey"])
      ])
    ]),
    _: 1
  }))
}